import * as React from 'react'
export const Web3CompaniesIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path fill='#000' d='M12 11H7v2h5v-2ZM7 15h5v2H7v-2Z' />
    <path
      fill='#000'
      d='M19 22H6a3 3 0 0 1-3-3V7.413a3 3 0 0 1 2.148-2.876l7-2.074A3 3 0 0 1 16 5.339V6c.277 0 .555.057.815.174l3.408 1.521A3 3 0 0 1 22 10.435V19a3 3 0 0 1-3 3Zm0-2a1 1 0 0 0 1-1v-8.566a1 1 0 0 0-.592-.913L16 8v12h3Zm-5 0V5.34a1 1 0 0 0-1.284-.96l-7 2.075A1 1 0 0 0 5 7.413V19a1 1 0 0 0 1 1h8Z'
    />
  </svg>
)

export const Web3CompaniesHoverIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path
      fill='#1C70EE'
      fillRule='evenodd'
      d='M4.465 4.926A2 2 0 0 0 3 6.854V20.5A1.5 1.5 0 0 0 4.5 22h9a1.5 1.5 0 0 0 1.5-1.5V4.631a2 2 0 0 0-2.535-1.927l-8 2.222ZM6.5 11h5v2h-5v-2Zm5 4h-5v2h5v-2Z'
      clipRule='evenodd'
    />
    <path
      fill='#1C70EE'
      d='M17 8.766a1 1 0 0 1 1.515-.857l2.757 1.654A1.5 1.5 0 0 1 22 10.85V20.5a1.5 1.5 0 0 1-1.5 1.5h-2a1.5 1.5 0 0 1-1.5-1.5V8.766Z'
    />
  </svg>
)

export const Web3JobHoverIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path
      fill='#1C70EE'
      fillRule='evenodd'
      d='M13.36 1.799a2.823 2.823 0 0 0-2.823 0L3.89 5.637a2.822 2.822 0 0 0-1.412 2.445v7.677a2.822 2.822 0 0 0 1.412 2.445l6.648 3.838a2.823 2.823 0 0 0 2.823 0l6.65-3.838a2.823 2.823 0 0 0 1.41-2.444V8.08a2.823 2.823 0 0 0-1.41-2.444l-6.65-3.838Zm-1.268 7.56-2.205 1.323L7.24 9.359l4.85-2.646 4.852 2.646-2.646 1.323-2.205-1.323Zm0 2.646 2.205-1.323 2.47 1.323-.265 2.646-4.41 2.646L7.24 14.65l2.646-1.323 2.205 1.323 2.205-1.323-2.205-1.323Z'
      clipRule='evenodd'
    />
  </svg>
)

export const Web3JobIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path
      fill='#000'
      stroke='#000'
      strokeWidth={0.067}
      d='M4.275 15.76V8.082a1.025 1.025 0 0 1 .512-.889l6.65-3.838a1.025 1.025 0 0 1 1.024 0l.017-.029-.017.029 6.65 3.838a1.026 1.026 0 0 1 .512.888v7.677a1.025 1.025 0 0 1-.512.889l-6.65 3.838a1.025 1.025 0 0 1-1.025 0l-6.649-3.838a1.026 1.026 0 0 1-.512-.889ZM20.026 5.607l-.014.024.014-.024-6.65-3.838a2.856 2.856 0 0 0-2.855 0L3.87 5.608a2.856 2.856 0 0 0-1.427 2.474v7.677a2.856 2.856 0 0 0 1.428 2.474l6.649 3.838a2.856 2.856 0 0 0 2.855 0l6.65-3.838a2.856 2.856 0 0 0 1.428-2.473V8.08a2.855 2.855 0 0 0-1.428-2.473Z'
    />
    <path
      fill='#000'
      fillRule='evenodd'
      d='m9.887 10.682 2.205-1.323 2.205 1.323 2.646-1.323-4.851-2.646L7.24 9.359l2.646 1.323Zm4.41 0-2.205 1.323 2.205 1.323-2.205 1.323-2.205-1.323L7.24 14.65l4.85 2.646 4.41-2.646.265-2.646-2.47-1.323Z'
      clipRule='evenodd'
    />
  </svg>
)
